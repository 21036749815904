import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import useStringBuilder from './use-string-builder-state'
import StringBuilderTemplate from './string-builder-template'
import Form from './string-builder-info-form'

export default function StringBuilder3(props) {
	const { settings } = props.pageContext
	const [state, setState] = useStringBuilder()

	useEffect(() => {
		if (!state.string) {
			let prev = document.location.pathname.split(`/`)
			if (!prev[prev.length - 1]) {
				prev.pop()
			}
			prev.pop()
			navigate(prev.join(`/`))
		}
		else if (!state.strand1 || !state.strand2 || !state.serving) {
			let prev = document.location.pathname.split(`/`)
			if (!prev[prev.length - 1]) {
				prev.pop()
			}
			prev.pop()
			prev.push(`2`)
			navigate(prev.join(`/`))
		}
	}, [])

	return (
		<StringBuilderTemplate
			settings={settings}
			stepNumber={3}
			title='Choose your bow type & specs'
			enableNext={validateInfo(state)}
		>
			<Form
				sbState={state}
				onChange={obj => {
					console.log(`Setting state`, obj)
					setState(obj)
				}}
			/>
		</StringBuilderTemplate>
	)
}

const required = [
	`strand1`,
	`strand2`,
	`serving`,
	`bowType`,
	`string`,
	`variant`,
	`stringCustomizationLevel`,
	`stringLength`,
	`stringSet`,
]
function validateInfo(state){
	for(let i = required.length; i--;){
		const key = required[i]
		if(state[key] == undefined || state[key] == null){
			console.log(`${key} not found in `, state)
			return false
		}
	}
	if (state.bowType === `compound` || state.bowType === `crossbow`){
		if (!state.bowModel || !state.bowBrand) {
			console.log(`bowModel or bowBrand not found in `, state)
			return false
		}
	}
	return true
}