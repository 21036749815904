import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import Link from '@app/link'
import get from 'lodash/get'
import { css } from '@emotion/core'
import { renderStyles } from '@app/sanity-style-fields/css'
import settings from '@data/sanity/stringBuilderdeveloperSettings.json'
import client from '@app/shopify-client'
import color from '@app/dynamic-color'
import Selector from './strands-selector'
import colors from '@data/sanity/colors.json'

const bowsEndpoint = `https://winners-choice-string-api.herokuapp.com/api/compound_bows`
// https://winners-choice-string-api.herokuapp.com/api/compound_bows?brand=Archery%20Research

export default class StringBuilderForm extends Component {

	constructor(props) {

		super(props)

		this.checkFormForErrors = this.checkFormForErrors.bind(this)
		this.checkIfAllClear = this.checkIfAllClear.bind(this)

		this.done = false

		this.state = {
			errors: true,
			bowBrandErrorMsg: ``,
			bowModelErrorMsg: ``,
			stringLengthErrorMsg: ``,
			stringCableSetNameErrorMsg: ``,
			bowBrandSelected: null,
			bowModelSelected: null,
			stringLength: null,
			bows: [],
			selectedStringCableSet: null,
			stringSet: ``,

			variants: [],
			models: [],
			brands: [],
		}
	}
	async componentDidMount() {
		const res = await fetch(bowsEndpoint)
		const brands = await res.json()
		this.setState({
			brands: brands.data,
		})
	}
	async fetchModels(brand){
		const res = await fetch(`${bowsEndpoint}?brand=${encodeURIComponent(brand)}`)
		const models = await res.json()
		if (this.state.bowBrand === brand) {
			this.setState({
				models: models.data,
			})
		}
	}


	// componentWillReceiveProps(nextProps) {
	// 	if (this.props !== nextProps) {
	// 		this.setState({
	// 			...nextProps,
	// 		})
	// 	}
	// }

	checkIfAllClear() {
		if (this.state.bowBrandErrorMsg === `` &&
			this.state.bowModelErrorMsg === `` &&
			this.state.stringLengthErrorMsg === `` &&
			this.state.stringCableSetNameErrorMsg === ``
		) {
			// need to limit to running once
			if (this.done === false) {
				this.setState({
					errors: false,
				}, () => { this.props.handleAddStringToCart() })
				this.done = true
			}
		} else {
			this.setState({
				errors: true,
			}, () => { return this.state })
		}
	}

	// function that occurs on click and handles a check
	// - the check validates the data in the form
	// - - if good, submits to the cart, else errors happen to guide person through
	checkFormForErrors(event) {
		// if (window.ga) {
		// 	window.ga(`send`, `event`, `Page Interaction`)
		// }
		event.preventDefault()
		if (this.state.bowBrandSelected === `no brand selected yet`) {
			this.setState({
				bowBrandErrorMsg: `Please select a bow brand from the dropdown list`,
			})
		} else {
			this.setState({
				bowBrandErrorMsg: ``,
			}, () => { this.checkIfAllClear() })
		}

		if (this.state.bowModelSelected === `no model selected yet`) {
			this.setState({
				bowModelErrorMsg: `Please select a bow model from the dropdown list`,
			})
		} else {
			this.setState({
				bowModelErrorMsg: ``,
			}, () => { this.checkIfAllClear() })
		}

		if (this.state.stringLength === null || this.state.stringLength === 0 || this.state.stringLength === ``) {
			this.setState({
				stringLengthErrorMsg: `If you do not know your string length, enter N/A`,
			})
		} else {
			this.setState({
				stringLengthErrorMsg: ``,
			}, () => { this.checkIfAllClear() })

		}

		if (this.state.selectedStringCableSet === `None selected yet`) {
			this.setState({
				stringCableSetNameErrorMsg: `Please select a string/cable set`,
			})
		} else {
			this.setState({
				stringCableSetNameErrorMsg: ``,
			}, () => { this.checkIfAllClear() })
		}
	}

	async fetchShopifyProduct(bowType){
		const ids = {
			standard: `Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzgyMzE3NTIxMzU=`,
			custom: `Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzgyMzE3NTM5Mjc=`,
			superCustom: `Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzgyMzE3NjM1Mjc=`,
			recurveLong: `Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzEwMDQzODg0MzU5`,
			crossbow: `Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzgyMzE4NTA4ODc=`,
		}
		let shopifyId
		const sb = this.props.sbState
		console.log(`bowType`, bowType)
		if(bowType === `long` || bowType === `recurve`){
			shopifyId = ids.recurveLong
			this.setState({ stringCustomizationLevel: `recurve-long-string` })
			this.props.onChange({ stringCustomizationLevel: `recurve-long-string`, bowType })
		}
		else if(bowType === `crossbow`){
			shopifyId = ids.crossbow
			this.setState({ stringCustomizationLevel: `crossbow-string` })
			this.props.onChange({ stringCustomizationLevel: `crossbow-string`, bowType })
		}
		else if(bowType === `compound`){

			if (sb.strand1.name === sb.strand2.name === sb.serving.name) {
				shopifyId = ids.standard
				this.setState({ stringCustomizationLevel: `standard-string` })
				this.props.onChange({ stringCustomizationLevel: `standard-string`, bowType })
			}
			else if (sb.string.id === `8190` && sb.serving.name === `Natural White`) {
				shopifyId = ids.custom
				this.setState({ stringCustomizationLevel: `custom-string` })
				this.props.onChange({ stringCustomizationLevel: `custom-string`, bowType })
			}

			// If the string is on 8190, and serving is not white, it is a super-custom-string
			else if (sb.string.id === `8190` && sb.serving.name !== `Natural White`) {
				shopifyId = ids.superCustom
				this.setState({ stringCustomizationLevel: `super-custom-string` })
				this.props.onChange({ stringCustomizationLevel: `super-custom-string`, bowType })
			}

			// If the string is on 8125G or 452X, it is a super-custom-string
			else if (sb.string.id === `8125G` || sb.string.id === `452x`) {
				shopifyId = ids.superCustom
				this.setState({ stringCustomizationLevel: `super-custom-string` })
				this.props.onChange({ stringCustomizationLevel: `super-custom-string`, bowType })
			}

			else {
				console.error(`Can't find shopifyID`)
			}

		}
		const data = await client.product.fetch(shopifyId)
		console.log(`Shopify string type`, data)
		this.setState({
			product: data,
			variants: data.variants,
		})
		this.props.onChange({ product: data })
	}

	render() {

		return (
			<>
				<Selector selector={false} />
				<form className="container normform" css={styles.form}>
					<div className="col-sm-3" css={!this.state.bowType ? styles.maxWidth : null}>
						<fieldset className="form-group col-sm-12" css={styles.typeCol}>
							<label css={styles.inputLabel}>BOW TYPE</label>

							<div className="radio">
								<label className="radio-inline" css={[styles.bowType, this.state.bowType === `compound` && styles.bowTypeActive]}>
									<input
										type="radio" name="bow_type"
										id="bow_type1" value="compound"
										checked={this.state.bowType === `compound` ? true : false}
										onChange={() => {
											this.setState({ bowType: `compound` })
											this.props.onChange({ bowType: `compound` })
											this.fetchShopifyProduct(`compound`)
										}}
									/>
								Compound
								</label>
							</div>

							<div className="radio">
								<label className="radio-inline" css={[styles.bowType, this.state.bowType === `crossbow` && styles.bowTypeActive]}>
									<input
										type="radio" name="bow_type"
										id="bow_type2" value="crossbow"
										checked={this.state.bowType === `crossbow` ? true : false}
										onChange={() => {
											this.setState({ bowType: `crossbow` })
											this.props.onChange({ bowType: `crossbow` })
											this.fetchShopifyProduct(`crossbow`)
										}}
									/>
								Crossbow
								</label>
							</div>

							<div className="radio">
								<label className="radio-inline" css={[styles.bowType, this.state.bowType === `recurve` && styles.bowTypeActive]}>
									<input
										type="radio" name="bow_type"
										id="bow_type3" value="recurve"
										checked={this.state.bowType === `recurve` ? true : false}
										onChange={() => {
											this.setState({ bowType: `recurve` })
											this.props.onChange({ bowType: `recurve` })
											this.fetchShopifyProduct(`recurve`)
										}}
									/>
                  Recurve
								</label>
							</div>

							<div className="radio">
								<label className="radio-inline" css={[styles.bowType, this.state.bowType === `long` && styles.bowTypeActive]}>
									<input
										type="radio" name="bow_type"
										id="bow_type4" value="long"
										checked={this.state.bowType === `long` ? true : false}
										onChange={() => {
											this.setState({ bowType: `long` })
											this.props.onChange({ bowType: `long` })
											this.fetchShopifyProduct(`long`)
										}}
									/>
                  Long
								</label>
							</div>
						</fieldset>
					</div>

					<div className="col-sm-9" css={this.state.bowType ? styles.show : styles.hide}>

						{(this.state.bowType === `compound` || this.state.bowType === `crossbow`) && (
							<>
								<div className={this.state.bowBrandErrorMsg !== `` ? `error` : ``}>
									<label css={styles.inputLabel} htmlFor="bowBrand">BOW BRAND</label>
									<select className="form-control" name="bowBrand" onChange={e => {
										const { value } = e.target
										this.fetchModels(value)
										this.setState({ bowBrand: value })
										this.props.onChange({ bowBrand: value })
									}} value={this.state.bowBrand}>
										<option value="">Please select a brand</option>
										{this.state.brands.map(brand => {
											const name = get(brand, `attributes.bow_brand`)
											return (
												<option key={name} value={name}>{name}</option>
											)
										})}
									</select>
									<div className="form-error-msg">{this.state.bowBrandErrorMsg}</div>
								</div>

								<div className={this.state.bowModelErrorMsg !== `` ? `error` : ``}>
									<label css={styles.inputLabel} htmlFor="bowModel">BOW MODEL <span><Link to="/contact">(model not listed?)</Link></span></label>
									<select className="form-control" name="bowModel" onChange={e => {
										this.props.onChange({ bowModel: e.target.value })
									}} value={this.state.bowModel}>
										<option value="">Please select a model</option>
										{this.state.bowBrand === `no brand selected yet` ? null : this.state.models.map(model => {
											// return null
											const name = get(model, `attributes.bow_model`)
											return (
												<option key={name} value={name}>{name}</option>
											)
										})}
									</select>
									<div className="form-error-msg">{this.state.bowModelErrorMsg}</div>
								</div>
							</>
						)}


						<div className={this.state.stringLengthErrorMsg !== `` ? `error` : ``}>
							<label css={styles.inputLabel} htmlFor="stringLength">STRING LENGTH (inches)</label>
							<input
								type="text"
								className="form-control"
								name="stringLength"
								placeholder="String Length"
								onChange={e => {
									this.setState({ stringLength: e.target.value })
									this.props.onChange({ stringLength: e.target.value })
								}}
								value={this.state.stringLength || undefined}
							/>
							<div className="form-error-msg">{this.state.stringLengthErrorMsg}</div>
						</div>

						<div className={this.state.stringCableSetNameErrorMsg !== `` ? `error` : ``}>
							<label css={styles.inputLabel} htmlFor="stringSet">STRING / CABLE SET</label>
							<select
								id="string-set"
								className="form-control"
								name="stringSet"
								onChange={e => {
									const id = e.target.value
									let variant
									console.log(`this.state.variants`, this.state.variants)
									this.state.variants.map(v => {
										if (id === v.title) {
											variant = v
										}
									})
									console.log(`Setting stringSet to `, variant.title)
									this.setState({ stringSet: variant.title })
									this.props.onChange({
										variant,
										stringSet: variant.title,
									})
								}}
								value={this.state.stringSet}
							>
								<option value="">Please select a string / cable set</option>
								{this.state.variants.map(variant => {
									return (
										<option key={variant.id} value={variant.title}>{variant.title} - ${variant.price}</option>
									)
								})}
							</select>
							<div className="form-error-msg">{this.state.stringCableSetNameErrorMsg}</div>
						</div>

						<label css={styles.inputLabel} htmlFor="notesToBuilder">NOTES TO BUILDER (optional)</label>
						<textarea
							className="form-control"
							id="add_notes"
							rows="4"
							type="text"
							tabIndex="4"
							placeholder="Split Buss Cable Length, Control Cable Length, Year bow was made, are there yolks, etc. Please include any details that you want us to be aware of when we build your string."
							name="add_notes"
							// value={this.state.notes}
							onChange={e => {
								this.props.onChange({ notesToBuilder: e.target.value })
							}}
						></textarea>
						{/* <button className="btn string-builder-add-cart" onClick={this.checkFormForErrors}>Add To Cart</button> */}
						<p className="purchase-disclaimer">* Due to the custom nature of the product, Winners Choice is not responsible for changes to build after the sale has been processed. All sales are final.</p>

					</div>

				</form>
			</>
		)
	}
}

StringBuilderForm.defaultProps = {
	bows: [],
	bowModels: [],
	priceCategory: [],
}

const styles = {
	show: css`
		display: block;
	`,
	hide: css`
		display: none;
	`,
	maxWidth: css`
		width: 100% !important;
	`,
	form: css`
		margin-top: 30px;
		border-top: 2px solid #000;
		border-top: 1px solid ${get(colors, `black.hex`, `black`)};
		padding-top: 60px;
		input, select{
			margin-bottom: 30px !important;
		}
		@media(min-width: 800px){
			display: flex;
			margin-left: -30px;
			margin-right: -30px;
			> *{
				padding: 0 30px;
				:first-of-type{
					width: 75%;
				}
			}
		}
	`,
	typeCol: css`
		border: 0 !important;
		box-shadow: none !important;
		margin: 0 !important;
		padding: 0 !important;
	`,
	inputLabel: css`
		${renderStyles(settings.inputLabelStyles)}
	`,
	bowType: css`
		text-align: center;
		text-transform: uppercase;
		${renderStyles(settings.bowTypeStyles)}
	`,
	bowTypeActive: css`
		border: 1px solid ${color(`wc-orange`, `black`)};
	`,
}